@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

@layer base {
    body {
        @apply font-[Poppins];
    }

    li {
        @apply px-4;
        @apply cursor-pointer;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@media screen and (min-width: 480px) {
    .w-full.h-screen.mt-64 {
        margin-top: 8rem;
    }
}

li svg {
    width: 1.6em;
    height: 1.6em;
    flex-shrink: 0;
}

.bg-img {
    background-image: url(./assets/DSC_7001.jpg);
    background-attachment: fixed;
    background-repeat: no-repeat;
        background-position: center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
}

.personnel-img {
    background-image: url(./assets/Gruppbild_Npi.jpg);
    background-color: blue;
    background-repeat: no-repeat;
    background-position: 50% 33%;
    -webkit-background-size: 50% 33%;
    -moz-background-size: 50% 33%;
    -o-background-size: 50% 33%;
    background-size: cover;
}

.blue-filter {
    background: linear-gradient(0deg, rgba(0, 74, 121, 0.7), rgba(0, 74, 121, 0.7));
}